
import { defineComponent } from "vue";
import Switch from "@/components/common/Switch.vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import IconRightButton from "@/components/alerts/settings/IconRightButton.vue";
import WrapCategory from "@/components/alerts/settings/WrapCategory.vue";

export default defineComponent({
  name: "AlertKeyword",
  components: {
    BaseHeader2,
    IconRightButton,
    WrapCategory,
    Switch,
  },
  data() {
    return {
      keyword: "",
      keywordList: [] as Array<string>,
      actorKeyword: true,
      staffKeyword: true,
    };
  },
  methods: {
    loadDeviceInfo(): void {
      this.$axios
        .get("/device/mobile")
        .then((res) => {
          this.actorKeyword = res.data.actor_keyword_agree;
          this.staffKeyword = res.data.staff_keyword_agree;
        })
        .catch(() => {
          const msg = "서버에 문제가 발생했습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        });
    },
    toggleActorKeywordAgree() {
      this.$axios
        .post("/device/mobile/keyword/actor/agree")
        .then((res) => {
          this.actorKeyword = res.data.actor_keyword_agree;
          const msg = "변경되었습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        })
        .catch(() => {
          const msg = "서버에 문제가 발생했습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        });
    },
    toggleStaffKeywordAgree() {
      this.$axios
        .post("/device/mobile/keyword/staff/agree")
        .then((res) => {
          this.staffKeyword = res.data.staff_keyword_agree;
          const msg = "변경되었습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        })
        .catch(() => {
          const msg = "서버에 문제가 발생했습니다.";
          this.$flutter.callHandler("showToast", {
            message: msg,
          });
        });
    },
    add() {
      if (this.keywordList.length < 30) {
        this.keywordList.push(this.keyword as string);
        this.callAdd();
      }
    },
    remove(item: string) {
      let index = this.keywordList.indexOf(item);
      if (index !== -1) {
        this.keywordList.splice(index, 1);
        this.callRemove(item);
      }
    },
    callAdd() {
      this.$axios
        .post("/push/alert/register/keyword", {
          keyword: this.keyword,
        })
        .then(() => {
          this.$pushGAEvent("alert_keyword_set_complete", {
            alert_keyword: this.keyword,
          });
          this.keyword = "";
        });
    },
    callRemove(keyword: string) {
      this.$axios
        .put("/push/alert/register/keyword", {
          keyword,
        })
        .then(() => {
          this.$pushGAEvent("alert_keyword_delete", {
            alert_keyword: keyword,
          });
        });
    },
  },
  beforeMount() {
    this.loadDeviceInfo();
  },
  mounted() {
    this.$pushGAEvent("alert_keyword_set_try");
    this.$axios.get("/push/alert/register/keyword").then((res) => {
      let list = res.data.results[0].keywords.map((x: { name: string }) => {
        return x.name;
      });

      if (res.data.count > 0) {
        this.keywordList.push(...list);
      }
    });
  },
});
