
import { defineComponent } from "vue";

export default defineComponent({
  name: "Switch",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
});
