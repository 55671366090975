
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    counter: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    limit: {
      type: Number,
      default: () => {
        return 1;
      },
    },
  },
});
