
import { defineComponent } from "vue";
import CloseIcon from "icons/Close.vue";

export default defineComponent({
  name: "IconRightButton",
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  components: { CloseIcon },
  emits: ["onClick"],
});
