
import { defineComponent } from "vue";
import Counter from "./Counter.vue";
import Wrap from "./Wrap.vue";

export default defineComponent({
  props: {
    count: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  components: { Counter, Wrap },
});
